import firebase from 'firebase/app';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, State } from 'vuex-class';
import AccountInvestments from '@/components/account/investments/AccountInvestments.vue';
import FundDocs from '@/components/common/fund-docs/FundDocs.vue';
import LazyImage from '@/components/common/lazyImage/LazyImage.vue';
import NetValue from '@/components/common/net-value/netValue.vue';
import ProgressBarDash from '@/components/common/progress-bar-dash/ProgressBarDash.vue';
import WithDownloads from '@/components/wrappers/downloads/WithDownloads.vue';
import { db } from '@/firebase';
import { State as StateClass } from '@/store/models';
import { Asset } from '@/store/models/asset';
import { Earning, Investment } from '@/store/models/investment';
import { Valuation } from '@/store/models/valuation';
import CollectionReference = firebase.firestore.CollectionReference;
import QuerySnapshot = firebase.firestore.QuerySnapshot;

@Component({
  components: {
    WithDownloads,
    FundDocs,
    ProgressBarDash,
    AccountInvestments,
    NetValue,
    LazyImage,
  },
})

export default class AccountSingleFund extends Vue {
  @Prop({ default: (): undefined => undefined, required: true }) asset!: Asset;
  @Prop({ default: (): undefined => undefined }) investment!: Investment;

  @State('state') payments!: StateClass['payments'];
  @State user!: StateClass['user'];

  @Getter getCombinedDividendsFormatByAsset!: Function;
  @Getter hasDifferentPaymentFormatsByAsset!: Function;
  @Getter getInvestmentByAsset!: Function;
  @Getter investmentHasPaidPayments!: Function;
  @Getter getActiveValuationByAsset!: Function;
  @Getter getAssetValuationSharePrice!: Function;
  @Getter getAssetValuationAvailableAmount!: Function;
  @Getter isUserLoggedIn!: boolean;

  showAllDividends = false;
  showHistory = false;
  historyView: 'investments' | 'dividends' = 'investments';
  dividendsPromise: null | Promise<QuerySnapshot<Earning>> = null;

  @Watch('historyView')
  async onHistoryViewChange(newHistoryView: 'investments' | 'dividends'): Promise<void> {
    if (newHistoryView === 'dividends' && !this.dividendsPromise) {
      const investorRef = db.collection('investors').doc(this.user!.id);
      this.dividendsPromise = (db.collection(`investments/${this.investment.id}/earnings`) as CollectionReference<Earning>)
        .where('investor', '==', investorRef)
        .where('deleted', '==', false)
        .orderBy('period', 'desc')
        .get();
    }
  }

  get getTotalShares(): number {
    return this.investment?.boughtSharesTotal || 0;
  }

  get hasPaidInvestments(): boolean {
    return this.investment?.id && this.investmentHasPaidPayments(this.investment.id);
  }

  get assetValuation(): Valuation | undefined {
    return this.asset && this.getActiveValuationByAsset(this.asset.id);
  }

  get assetSharePrice(): number | undefined {
    return this.asset && this.getAssetValuationSharePrice(this.asset.id);
  }

  get availableAmount(): number | undefined {
    return this.asset && this.getAssetValuationAvailableAmount(this.asset);
  }

  changeView(view: 'investments' | 'dividends'): void {
    this.historyView = view;
  }

  getDividends(data: firebase.firestore.QuerySnapshot): Earning[] {
    return data.docs.map((doc): Earning => doc.data() as Earning);
  }
}
